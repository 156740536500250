import * as React from "react"
import Header from "../components/header"
import HeaderTop from "../components/headertop"
import FooterForm from "../components/form-footer"
import Footer from "../components/footer"
import { Link } from "gatsby"


// styles
const pageStyles = {
  color: "#fff",
  paddingTop: 0,
  paddingLeft: 0,
  padingRight: 0,
}

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <HeaderTop />
        <Header />
        <title>Not found</title>
      <h1>Page not found</h1>
      <p>
        Sorry
        we couldn’t find what you were looking for.
        <br />
        <Link to="/">Go home</Link>.
      </p>
        <FooterForm />
      <Footer />
    </main>
  )
}

export default NotFoundPage